import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import {
  ArrowRightIcon,
  CircleIcon,
  CheckCircledIcon,
  CrossCircledIcon,
  QuestionMarkCircledIcon
} from '@radix-ui/react-icons';

import { TasksContext } from '../../contexts/contexts'
import { UserResponsesContext } from '../../contexts/contexts'

// set a default class for nav items that lead to internal pages
// set as a named export
export const NavItem = ({ children, to, type, task, join, update }) => {

  const [added, setAdded] = useState(false)

  const classes = () => {
    if (join) { return 'nav join-item' }
    if (type === "tool") { return 'nav tool' }
    if (type === "task" && added) { return 'nav added' }
    if (type === "complete") { return 'btn w-full sm:w-auto btn-success ml-auto'}
    return 'nav'
  }

  const { setUserResponses } = useContext(UserResponsesContext)

  // update the UserResponsesContext object
  const handleClick = (updateKey, newResponse) => {
    if (newResponse !== undefined) {
      setUserResponses(prevUserResponses => ({ ...prevUserResponses, [updateKey]: newResponse}))
    }
  }

  // update the TasksContext object
  const { handleAddTask } = useContext(TasksContext)

  const handleAdded = () => {
    setAdded(true)
    handleAddTask(task)
  }

  let icon
  switch(type) {
    case 'yes':
    case 'done':
      icon = <CheckCircledIcon className="large-icon" aria-hidden="true" />
      break
    case 'no':
      icon = <CrossCircledIcon className="large-icon" aria-hidden="true" />
      break
    case 'question':
      icon = <QuestionMarkCircledIcon className="large-icon" aria-hidden="true" />
      break
    case 'task':
      if (added) {
        icon = <CheckCircledIcon className="large-icon" aria-hidden="true" />
      }
      else {
        icon = <CircleIcon className="large-icon" aria-hidden="true" />
      }
      break
    default:
      icon = <ArrowRightIcon className="large-icon" aria-hidden="true" />
  }
  if (typeof update?.key === 'string' && update?.value !== null) {
    return (
      <Link tabIndex={0} to={to} className={classes()} onClick={() => handleClick(update.key, update.value)}>{children}{icon}</Link>
    )
  }
  else if (type === 'task') {
    return (
      <button disabled={added} className={classes()} onClick={handleAdded}>{added ? 'Added!' : children}{icon}</button>
    )
  }
  else {
    return (
      <Link to={to} className={classes()}>{children}{icon}</Link>
    )
  }
}