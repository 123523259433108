import React, { useEffect, useState } from 'react'

import Two from '../../images/home/home-2.jpg'
import Three from '../../images/home/home-3.jpg'
import Four from '../../images/home/home-4.jpg'
import Five from '../../images/home/home-5.jpg'
import Six from '../../images/home/home-6.jpg'
import Eight from '../../images/home/home-8.jpg'
import Nine from '../../images/home/home-9.jpg'
import Ten from '../../images/home/home-10.jpg'

const HomePageImg = () => {

  const [selectedImg, setSelectedImg] = useState([])

  const imgPaths = [
    Two,
    Three,
    Four,
    Five,
    Six,
    Eight,
    Nine,
    Ten
  ]

  const imgAlts = [
    "Two people sitting down together, smiling.",
    "A family preparing a meal in the kitchen.",
    "A young person working in what seems to be a brewery, about to move some kegs.",
    "An older person sitting at a laptop with bright green foliage behind.",
    "A young person bent over tying their shoelace.",
    "Two older people sitting together on a bench on a bright day with clear skies, looking out towards mountains.",
    "Two people following along a wide trail in a wooded area.",
    "Two people going down the sidewalk."
  ]

  const imgCredits = [
    'Unsplash / Yusron El Jihan',
    'Pexels / August de Richelieu',
    'Pexels / cottonbro studio',
    'Pexels / Marcus Aurelius',
    'Pexels / Polina Tankilevitch',
    'Unsplash / Matt Bennett',
    'Unsplash / Annie Spratt',
    'Disabled And Here Project / Photo: Tojo Andrianarivo / Make-up artistry: Lana Shapiro'
  ]

  useEffect(() => {
    const random = Math.floor(Math.random() * imgPaths.length)
    setSelectedImg([imgPaths[random], imgAlts[random], imgCredits[random]])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (selectedImg) {
    return (
      <figure className="hidden lg:flex flex-wrap w-full min-w-[280px] sm:w-[300px] sm:min-h-[500px] max-h-[540px] mb-4">
        <img
          loading="lazy"
          src={selectedImg[0]}
          className="object-cover rounded-r-lg xl:rounded-lg bg-gray-300"
          alt={selectedImg[1]}
          width="760"
          height="1000"
        />
        <figcaption className="text-xs p-2 opacity-50"><p>{selectedImg[2]}</p></figcaption>
      </figure>
    )

  }
}

export default HomePageImg
