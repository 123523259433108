import React, { createContext, useState } from 'react'

export const UserNavHistoryContext = createContext([])
export const UserSettingsContext = createContext({})
export const TasksContext = createContext()
export const UserResponsesContext = createContext({
  canSee: 'unset',
  canSpeak: 'unset',
  difficultyEating: 'unset',
  difficultyCooking: 'unset',
  requiresLst: 'unset',
  consent: 'unset',
  completed: 'unset',
  selectedRestrictions: [],
  restrictionResponses: {}
})

export const TasksProvider = ({ children }) => {

  const [tasks, setTasks] = useState([])
  const [taskId, setTaskId] = useState(0)

  const defaultTasks = [
    {
      name: 'Find a medical practitioner who can certify my DTC restriction',
      done: false
    },
    {
      name: 'Decide if I want to complete the DTC online, over the phone, or with the paper form',
      done: false
    },
    {
      name: 'Complete Part A of the DTC',
      done: false
    },
    {
      name: 'Book an appointment with my medical practitioner to complete Part B of the DTC',
      done: false
    },
    {
      name: 'Try the My DTC restrictions questionnaire PDF letter tool to help prepare to meet with my medical practitioner',
      done: false
    },
    {
      name: 'Meet with my medical practitioner',
      done: false
    },
    {
      name: 'Submit the completed DTC form',
      done: false
    },
  ]

  const handleAddTask = (task) => {
    if (task !== '') {
      const taskExists = tasks.some(t => t.name === task)
      if (!taskExists) {
        const newTask = {
          id: taskId,
          name: task,
          done: false
        };
        setTasks([...tasks, newTask])
        setTaskId(taskId + 1)
      }
    }
  }

  const handleChangeTask = (nextTask) => {
    const updatedTasks = tasks.map(task => task.id === nextTask.id ? nextTask : task)
    setTasks(updatedTasks)
  }

  const handleDefaultTasks = () => {
    let currentTaskId = taskId
    const newTasks = defaultTasks.filter(defaultTask => 
      !tasks.some(task => task.name === defaultTask.name)
    ).map(task => ({
      ...task,
      id: currentTaskId++
    }))
  
    setTasks([...tasks, ...newTasks]);
    setTaskId(currentTaskId);
  };
  

  const handleDeleteTask = (taskId) => {
    const remainingTasks = tasks.filter(task => task.id !== taskId)
    setTasks(remainingTasks)
  }

  return (
    <TasksContext.Provider value={{ tasks, defaultTasks, handleAddTask, handleChangeTask, handleDefaultTasks, handleDeleteTask }}>
      {children}
    </TasksContext.Provider>
  )
}