import React from 'react'

const Card = ({ number, header, children }) => {

  return (
    <div className="card mt-6 lg:card-side bg-white shadow-md">
      <div className="bg-blue-600 text-white font-bold rounded-t-lg lg:rounded-tr-none lg:rounded-l-lg flex items-center py-2 px-4 min-w-48">
        <p className="mb-2"><span className="block text-5xl mb-3">{number}</span>{header}</p>
      </div>
      <div className="card-body px-5 sm:px-8">
        {children}
      </div>
    </div>
  )
}

export default Card