exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-community-contact-mdx": () => import("./../../../src/pages/community/contact.mdx" /* webpackChunkName: "component---src-pages-community-contact-mdx" */),
  "component---src-pages-community-index-mdx": () => import("./../../../src/pages/community/index.mdx" /* webpackChunkName: "component---src-pages-community-index-mdx" */),
  "component---src-pages-handbook-appealing-mdx": () => import("./../../../src/pages/handbook/appealing.mdx" /* webpackChunkName: "component---src-pages-handbook-appealing-mdx" */),
  "component---src-pages-handbook-benefits-of-dtc-mdx": () => import("./../../../src/pages/handbook/benefits-of-dtc.mdx" /* webpackChunkName: "component---src-pages-handbook-benefits-of-dtc-mdx" */),
  "component---src-pages-handbook-eligibility-examples-mdx": () => import("./../../../src/pages/handbook/eligibility-examples.mdx" /* webpackChunkName: "component---src-pages-handbook-eligibility-examples-mdx" */),
  "component---src-pages-handbook-eligibility-mdx": () => import("./../../../src/pages/handbook/eligibility.mdx" /* webpackChunkName: "component---src-pages-handbook-eligibility-mdx" */),
  "component---src-pages-handbook-finding-medical-practitioner-mdx": () => import("./../../../src/pages/handbook/finding-medical-practitioner.mdx" /* webpackChunkName: "component---src-pages-handbook-finding-medical-practitioner-mdx" */),
  "component---src-pages-handbook-get-help-with-dtc-mdx": () => import("./../../../src/pages/handbook/get-help-with-dtc.mdx" /* webpackChunkName: "component---src-pages-handbook-get-help-with-dtc-mdx" */),
  "component---src-pages-handbook-index-mdx": () => import("./../../../src/pages/handbook/index.mdx" /* webpackChunkName: "component---src-pages-handbook-index-mdx" */),
  "component---src-pages-handbook-individuals-mdx": () => import("./../../../src/pages/handbook/individuals.mdx" /* webpackChunkName: "component---src-pages-handbook-individuals-mdx" */),
  "component---src-pages-handbook-individuals-step-1-preparing-mdx": () => import("./../../../src/pages/handbook/individuals-step-1-preparing.mdx" /* webpackChunkName: "component---src-pages-handbook-individuals-step-1-preparing-mdx" */),
  "component---src-pages-handbook-individuals-step-2-completing-mdx": () => import("./../../../src/pages/handbook/individuals-step-2-completing.mdx" /* webpackChunkName: "component---src-pages-handbook-individuals-step-2-completing-mdx" */),
  "component---src-pages-handbook-individuals-step-3-certifying-mdx": () => import("./../../../src/pages/handbook/individuals-step-3-certifying.mdx" /* webpackChunkName: "component---src-pages-handbook-individuals-step-3-certifying-mdx" */),
  "component---src-pages-handbook-individuals-step-4-submitting-mdx": () => import("./../../../src/pages/handbook/individuals-step-4-submitting.mdx" /* webpackChunkName: "component---src-pages-handbook-individuals-step-4-submitting-mdx" */),
  "component---src-pages-handbook-individuals-step-5-next-steps-mdx": () => import("./../../../src/pages/handbook/individuals-step-5-next-steps.mdx" /* webpackChunkName: "component---src-pages-handbook-individuals-step-5-next-steps-mdx" */),
  "component---src-pages-handbook-life-sustaining-therapy-mdx": () => import("./../../../src/pages/handbook/life-sustaining-therapy.mdx" /* webpackChunkName: "component---src-pages-handbook-life-sustaining-therapy-mdx" */),
  "component---src-pages-handbook-practitioners-common-misconceptions-about-dtc-mdx": () => import("./../../../src/pages/handbook/practitioners-common-misconceptions-about-dtc.mdx" /* webpackChunkName: "component---src-pages-handbook-practitioners-common-misconceptions-about-dtc-mdx" */),
  "component---src-pages-handbook-practitioners-guide-mdx": () => import("./../../../src/pages/handbook/practitioners-guide.mdx" /* webpackChunkName: "component---src-pages-handbook-practitioners-guide-mdx" */),
  "component---src-pages-handbook-practitioners-mdx": () => import("./../../../src/pages/handbook/practitioners.mdx" /* webpackChunkName: "component---src-pages-handbook-practitioners-mdx" */),
  "component---src-pages-handbook-practitioners-patient-eligibility-mdx": () => import("./../../../src/pages/handbook/practitioners-patient-eligibility.mdx" /* webpackChunkName: "component---src-pages-handbook-practitioners-patient-eligibility-mdx" */),
  "component---src-pages-handbook-practitioners-step-1-is-part-a-done-mdx": () => import("./../../../src/pages/handbook/practitioners-step-1-is-part-a-done.mdx" /* webpackChunkName: "component---src-pages-handbook-practitioners-step-1-is-part-a-done-mdx" */),
  "component---src-pages-handbook-practitioners-step-2-completing-mdx": () => import("./../../../src/pages/handbook/practitioners-step-2-completing.mdx" /* webpackChunkName: "component---src-pages-handbook-practitioners-step-2-completing-mdx" */),
  "component---src-pages-handbook-practitioners-step-3-submitting-mdx": () => import("./../../../src/pages/handbook/practitioners-step-3-submitting.mdx" /* webpackChunkName: "component---src-pages-handbook-practitioners-step-3-submitting-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-privacy-policy-and-terms-conditions-mdx": () => import("./../../../src/pages/privacy-policy-and-terms-conditions.mdx" /* webpackChunkName: "component---src-pages-privacy-policy-and-terms-conditions-mdx" */),
  "component---src-pages-tasks-index-mdx": () => import("./../../../src/pages/tasks/index.mdx" /* webpackChunkName: "component---src-pages-tasks-index-mdx" */),
  "component---src-pages-tool-apologies-mdx": () => import("./../../../src/pages/tool/apologies.mdx" /* webpackChunkName: "component---src-pages-tool-apologies-mdx" */),
  "component---src-pages-tool-complete-mdx": () => import("./../../../src/pages/tool/complete.mdx" /* webpackChunkName: "component---src-pages-tool-complete-mdx" */),
  "component---src-pages-tool-index-mdx": () => import("./../../../src/pages/tool/index.mdx" /* webpackChunkName: "component---src-pages-tool-index-mdx" */),
  "component---src-pages-tool-questions-mdx": () => import("./../../../src/pages/tool/questions.mdx" /* webpackChunkName: "component---src-pages-tool-questions-mdx" */),
  "component---src-pages-tool-start-mdx": () => import("./../../../src/pages/tool/start.mdx" /* webpackChunkName: "component---src-pages-tool-start-mdx" */),
  "component---src-pages-tool-your-restrictions-mdx": () => import("./../../../src/pages/tool/your-restrictions.mdx" /* webpackChunkName: "component---src-pages-tool-your-restrictions-mdx" */)
}

