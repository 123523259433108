import React, { useContext } from 'react'
import * as ToggleGroup from '@radix-ui/react-toggle-group'

import { UserSettingsContext } from '../../contexts/contexts'

const Settings = () => {

  const { userSettings, setUserSettings } = useContext(UserSettingsContext)

  const handleValueChange = (newSetting, key) => {
    if (newSetting) {
      setUserSettings(prevUserSettings => ({ ...prevUserSettings, [key]: newSetting}))
    }
  }

  return (
    <section className="settings text-sm overflow-x-auto">
      <header><h2 className="pt-0 mb-2">Settings</h2></header>
      <h3 className="pt-2 text-base">Display size</h3>
      <ToggleGroup.Root
        type="single"
        aria-label="Text size"
        value={userSettings.displaySize}
        onValueChange={(value) => handleValueChange(value, 'displaySize')}
        className="join"
      >
        <ToggleGroup.Item className="btn btn-sm join-item" value="small">Small</ToggleGroup.Item>
        <ToggleGroup.Item className="btn btn-sm join-item" value="medium">Medium</ToggleGroup.Item>
        <ToggleGroup.Item className="btn btn-sm join-item" value="large">Large</ToggleGroup.Item>
        <ToggleGroup.Item className="btn btn-sm join-item" value="xlarge">Extra large</ToggleGroup.Item>
      </ToggleGroup.Root>
      <h3 className="pt-4 text-base">Colours</h3>
      <ToggleGroup.Root
        type="single"
        aria-label="Theme"
        value={userSettings.theme}
        onValueChange={(value) => handleValueChange(value, 'theme')}
        className="join"
      >
        <ToggleGroup.Item className="btn btn-sm join-item" value="dabc">Light</ToggleGroup.Item>
        <ToggleGroup.Item className="btn btn-sm join-item" value="retro">Retro</ToggleGroup.Item>
        <ToggleGroup.Item className="btn btn-sm join-item" value="night">Dark</ToggleGroup.Item>
      </ToggleGroup.Root>
      <h3 className="pt-4 text-base">Font</h3>
      <ToggleGroup.Root
        type="single"
        aria-label="Theme"
        value={userSettings.fontName}
        onValueChange={(value) => handleValueChange(value, 'fontName')}
        className="join"
      >
        <ToggleGroup.Item className="btn btn-sm join-item lexend" value="lexend">Lexend</ToggleGroup.Item>
        <ToggleGroup.Item className="btn btn-sm join-item noto" value="sans">Noto Sans</ToggleGroup.Item>
        <ToggleGroup.Item className="btn btn-sm join-item lora" value="serif">Lora</ToggleGroup.Item>
      </ToggleGroup.Root>
    </section>
  )
}

export default Settings