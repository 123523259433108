import React, { useContext, useEffect, useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import {
  CircleIcon,
  CheckCircledIcon
} from '@radix-ui/react-icons'
import { UserResponsesContext } from '../../contexts/contexts'
import { useUpdateUserResponses } from '../../hooks/use-update-user-responses'

const QuestionMultiSelectScale = ({ scale, scaleLabel, scaleHideOn, restriction, generates, options, children, other }) => {

  const { userResponses } = useContext(UserResponsesContext)
  const updateUserResponses = useUpdateUserResponses()

  const [inputs, setInputs] = useState([])

  // check if values exist
  useEffect(() => {
    const responseValue = userResponses?.restrictionsResponses?.[restriction]?.[generates] ? userResponses?.restrictionsResponses?.[restriction]?.[generates] : null;
    if (Array.isArray(responseValue) && responseValue.length > 0) {
      setInputs(responseValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // run when inputs changes
  useEffect(() => {
    updateUserResponses(restriction, generates, inputs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs])

  const handleInputChange = (newValue, option, inputKey, isOther) => {
    // Check if newValue is an event object or a string
    const target = newValue
    setInputs(inputs => {
      const newInputs = [...inputs]
      let index = newInputs.findIndex(input => input.label === option)
      if (index === -1) {
        !isOther ? newInputs.push({ label: option, [inputKey]: target }) : newInputs.push({ label: option, [inputKey]: target, other: true, required: 'value' })
      } else {
        newInputs[index][inputKey] = target
      }
      return newInputs
    })
  }

  const responseScale = scale ? scale : ['Rarely', 'Sometimes', 'Often', 'Almost always', 'Always']
  // Get the stored other value for this question, if it exists
  const otherObject = inputs.find(input => input.other)

  return (
    <>
      {children}
      {options &&
        options.map((option) => {
          // If the option is an array rather than a string, then we want to output the 2nd item in the array in the generated PDF, but use the first item for the user-facing labels.
          const isArray = Array.isArray(option)
          // Find the corresponding input based on the value
          const input = inputs.find(input => input.label === (isArray ? option[1] : option))
          return (
            <Accordion.Root
              key={isArray ? option[1] : option} // Use the option as the key
              type="single"
              value={input?.label || ''}
              onValueChange={event => handleInputChange(event, isArray ? option[1] : option, 'label')} // Pass the option as the index
              collapsible="true"
            >
              <Accordion.Item value={isArray ? option[1] : option}>
                <Accordion.Trigger className="button nav scale" value={`item-${isArray ? option[1] : option}`}>
                  {isArray ? option[0] : option}
                  <CircleIcon className="large-icon unchecked" aria-hidden="true" />
                  <CheckCircledIcon className="large-icon checked" aria-hidden="true" />
                </Accordion.Trigger>
                <Accordion.Content className="scale-container">
                  {responseScale.length > 0 && (!scaleHideOn || !scaleHideOn.includes(isArray ? option[1] : option)) ?
                    <ToggleGroup.Root
                      type="single"
                      aria-label={scaleLabel ? scaleLabel : 'How often?'}
                      value={input?.value || ''}
                      onValueChange={event => handleInputChange(event, isArray ? option[1] : option, 'value')} // Pass the option as the index
                    >
                      <p className="mb-2"><strong>{scaleLabel ? scaleLabel : 'How often?'}</strong></p>
                      <div className="lg:flex gap-1">
                      {
                        responseScale.map((scaleOption, scaleIndex) => {
                          const scaleIsArray = Array.isArray(scaleOption)
                          return ( 
                            <ToggleGroup.Item
                              key={`scale-${scaleIndex}`}
                              className="radio-scale"
                              value={scaleIsArray ? scaleOption[1] : scaleOption}
                            >
                              <span>{scaleIsArray ? scaleOption[0] : scaleOption}</span>
                              <CircleIcon className="large-icon unchecked" aria-hidden="true" />
                              <CheckCircledIcon className="large-icon checked" aria-hidden="true" />
                            </ToggleGroup.Item>
                          )
                        })
                      }
                      </div>
                    </ToggleGroup.Root> :
                    <code>No scale defined</code>
                  }
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          )
        })
      }
      {
        other != null ?
          other.type === 'multi' ?
            <label className="form-control" id="textarea-label">
              <div className="label">
                <strong>{other.label ? other.label : 'Other'}</strong>
              </div>
              <textarea
                aria-labelledby="textarea-label"
                value={otherObject?.value || ''}
                onChange={event => handleInputChange(event.target.value, other.label ? other.label : 'Other', 'value', true)}
                placeholder={other.placeholder ? other.placeholder : 'Type here'}
                className="textarea textarea-secondary bg-white"
                rows={5}
              />
            </label> :
            <label className={(otherObject?.other && otherObject?.value !== '') ? 'inset-input nav active' : 'inset-input nav'} id="input-label">
              {other.label ? other.label : 'Other'}
              <input
                aria-labelledby="input-label"
                value={otherObject?.value || ''}
                type="text"
                // PASS OTHER: TRUE to the object
                onChange={event => handleInputChange(event.target.value, other.label ? other.label : 'Other', 'value', true)}
                className="grow"
                placeholder="Type here"
              />
              <CircleIcon className="large-icon unchecked" aria-hidden="true" />
              <CheckCircledIcon className="large-icon checked" aria-hidden="true"/>
            </label>
          :
          null
        }
    </>
  )
}

export default QuestionMultiSelectScale