import { useContext } from 'react'
import { UserResponsesContext } from '../contexts/contexts'

// Update the UserResponsesContext object with the changed values
export const useUpdateUserResponses = () => {
  const { setUserResponses } = useContext(UserResponsesContext)
  // create a new state object that includes all previous responses and a modified restrictionsResponses property
  return (restriction, generates, newValue) => {
    setUserResponses(prevUserResponses => {
      // Update the specific restriction based on whether newValue is a string or an object
      // Objects should contain keys of label and value
      const prevRestrictions = prevUserResponses.restrictionsResponses?.[restriction] || {};
      const prevGenerates = prevRestrictions?.[generates];

      let updatedValue

      if (Array.isArray(newValue)) {
        updatedValue = newValue;
      } else if (typeof newValue === 'object') {
        if (prevGenerates) {
          const updatedGenerates = prevGenerates.map(obj => obj.label === newValue.label ? newValue : obj)
          const isNewValueExists = prevGenerates.some(obj => obj.label === newValue.label)
          updatedValue = isNewValueExists ? updatedGenerates : [...updatedGenerates, newValue]
        } else {
          updatedValue = [newValue]
        }
      } else {
        updatedValue = newValue
      }

      const updatedRestriction = {
        ...prevRestrictions,
        [generates]: updatedValue
      }
      // Filter out entries with empty string values or objects with empty values and/or labels
      const filteredRestriction = Object.fromEntries(
        Object.entries(updatedRestriction).map(([key, value]) => {
          if (Array.isArray(value)) {
            const filteredArray = value.filter(obj => {
              if (typeof obj === 'object') {
                if (obj.required === 'both') { return obj.label && obj.value }
                else if (obj.required === 'value') { return obj.value }
                else { return obj.label }
              }
              else {
                return obj; // keep the string values
              }
            })
            return [key, filteredArray];
          } else {
            return [key, value];
          }
        }).filter(([_, value]) => Array.isArray(value) ? value.length > 0 : value !== '')
      )

      return {
        ...prevUserResponses,
        restrictionsResponses: {
          ...prevUserResponses.restrictionsResponses,
          [restriction]: filteredRestriction
        }
      }
    })
  }
}