import React, { useContext } from 'react'
import { UserResponsesContext } from '../../contexts/contexts'
import { useHandleValueChange } from '../../hooks/use-handle-value-change'

const QuestionMultipleTexts = ({ options, restriction, generates, children }) => {

  const { userResponses } = useContext(UserResponsesContext)
  const handleValueChange = useHandleValueChange(restriction, generates)

  const arrayValue = userResponses?.restrictionsResponses?.[restriction]?.[generates] || []

  return (
    <>
      {children}
      {
        options.map((option) => {
          return (
            <label className="inset-input my-3" key={option}>
              {option ? option : 'Your response'}
              <input
                value={arrayValue.find(obj => obj.label === option)?.value || ''}
                type="text"
                onChange={event => handleValueChange({label: option, value: event.target.value, required: 'both'})}
                className="grow"
                placeholder="Type here"
              />
            </label>
          )
        })
      }
    </>
  )
}

export default QuestionMultipleTexts