import React, { useContext } from 'react'
import { UserResponsesContext } from '../../contexts/contexts'
import { useHandleValueChange } from '../../hooks/use-handle-value-change'

const QuestionMultiText = ({ type, restriction, generates, label, min, max, placeholder, children }) => {

  const { userResponses } = useContext(UserResponsesContext)
  const handleValueChange = useHandleValueChange(restriction, generates)

  // Get the stored values for this question, if it exists
  const textFieldValue = userResponses?.restrictionsResponses?.[restriction]?.[generates] || ''

  if (type === 'number') {
    return (
      <>
        {children}
        <label className="form-control">
          <div className="label">
            {label ? label : 'Year'}
          </div>
          <input
            value={textFieldValue}
            placeholder={placeholder ? placeholder : 'YYYY'}
            onChange={event => handleValueChange(event.target.value)}
            type="number"
            className="input input-secondary bg-white w-32"
            min={min ? min : 1924}
            max={max ? max : 2044}
            step="1"
            pattern="[0-9]{4}"
            size="4"
          />
        </label>
      </>
    )
  }
  else {
    return (
      <>
        {children}
        <label className="form-control">
          <div className="label">
            {label ? label : 'Please type your response below.'}
          </div>
          { type === 'multi' ?
          <textarea
            value={textFieldValue}
            onChange={event => handleValueChange(event.target.value)}
            className="textarea textarea-secondary bg-white"
            rows={5}
          /> :
          <input
            value={textFieldValue}
            onChange={event => handleValueChange(event.target.value)}
            className="input input-secondary bg-white"
            type="text"
          />
          }
        </label>
      </>
    )
  }
}

export default QuestionMultiText