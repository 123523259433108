import React, { useState } from 'react'
import {
  PlusIcon
} from '@radix-ui/react-icons';

const TasksAdd = ({ onAddTask }) => {

  const [task, setTask] = useState('')

  return (
    <div className="join w-full">
      <input
        className="px-3 input input-bordered join-item w-full"
        placeholder="Add new task"
        value={task}
        onChange={event => setTask(event.target.value)}
      />
      <button
        className="btn btn-secondary join-item"
        aria-label="Add task"
        onClick={() => {
          onAddTask(task)
          setTask('')
        }}
      >Add<PlusIcon aria-hidden="true" />
      </button>
    </div>
  )
}

export default TasksAdd