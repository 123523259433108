import React from 'react'
import QuestionMultiSelect from '../sub/question-multi-select'
import QuestionMultiSelectScale from '../sub/question-multi-select-scale'
import QuestionText from '../sub/question-text'
import QuestionSingleSelect from '../sub/question-single-select'
import QuestionSingleSelectScale from '../sub/question-single-select-scale'
import QuestionConditionsYears from '../sub/question-conditions-years'
import QuestionMultipleTexts from '../sub/question-multiple-texts'
import QuestionLstCheck from '../sub/question-lst-check'

const Question = ({ type, update, ...props }) => {

  return (
    <div className={type !== 'lst-check' ? 'question' : 'lst-check'}>
      {(() => {
        switch(type) {
        case 'conditions-years':
          return <QuestionConditionsYears {...props} />
        case 'single-select':
          return <QuestionSingleSelect {...props} />
        case 'single-select-scale':
          return <QuestionSingleSelectScale {...props} />
        case 'yes-no':
          return <QuestionSingleSelect update={update} type="yesno" {...props} />
        case 'multi-select':
          return <QuestionMultiSelect {...props} />
        case 'multi-select-scale':
          return <QuestionMultiSelectScale {...props} />
        case 'text':
          return <QuestionText {...props} />
        case 'multi-text':
          return <QuestionText type="multi" {...props} />
        case 'number':
          return <QuestionText type="number" {...props} />
        case 'multiple-texts':
          return <QuestionMultipleTexts {...props} />
        case 'lst-check':
          return <QuestionLstCheck {...props} />
        default:
          return <p className="mt-12">Looks like your question type is invalid. Try checking the spelling. Valid question types are <code>conditions-years</code>, <code>multi-select</code>, <code>single-select</code>, <code>multi-text</code>, and <code>single-select</code>.</p>
        }
      })()}
    </div>
  )
}

export default Question