import React from 'react'
import {
  InfoCircledIcon
} from '@radix-ui/react-icons';

const InfoPanel = ({ header, children }) => (
  <div className="info-panel mt-8 mb-0 px-3 sm:px-6 pt-4 pb-2 bg-accent rounded-lg">
    <h2 className="flex items-center gap-2 text-secondary pt-2 text-xl"><InfoCircledIcon className="large-icon" aria-hidden="true" />{header}</h2>
    {children}
  </div>
)

export default InfoPanel