import React from 'react'
import WarningPanel from './warning-panel'
import { Link } from 'gatsby'

const PrivacyWarning = ({ use }) => (
  <WarningPanel header="Privacy Policy & Terms and Conditions">
    <p>To use {use ? use : 'My DTC'}, you must understand, agree, and consent to <Link to="/privacy-policy-and-terms-conditions" className="link">our privacy policy & terms and conditions</Link>.</p>
  </WarningPanel>
)

export default PrivacyWarning