import React from 'react'
import {
  ExclamationTriangleIcon
} from '@radix-ui/react-icons';

const WarningPanel = ({ header, children }) => (
  <div className="mt-8 mb-0 px-6 pt-4 pb-2 bg-warning rounded-lg">
    <h2 className="flex items-center gap-2 pt-2 text-xl"><ExclamationTriangleIcon className="large-icon" aria-hidden="true" />{header}</h2>
    {children}
  </div>
)

export default WarningPanel