import React, { useContext, useState } from 'react'
import * as Form from '@radix-ui/react-form'
import * as Dialog from '@radix-ui/react-dialog'
import { TasksContext } from '../../contexts/contexts'
import { Link } from 'gatsby'
import {
  ChatBubbleIcon,
  Cross2Icon,
  DownloadIcon,
  ExclamationTriangleIcon,
  PlusIcon
} from '@radix-ui/react-icons'
import TasksAdd from '../sub/tasks-add'
import TasksList from '../sub/tasks-list'
import { BaseLogo } from '../sub/base-logo'
import Expander from '../sub/expander'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import unchecked from '../../../static/unchecked.png'
import checked from '../../../static/checked.png'

let htmlToPdfmake = require('html-to-pdfmake')

const Tasks = ({ onPage }) => {
  const { tasks, defaultTasks, handleAddTask, handleChangeTask, handleDefaultTasks, handleDeleteTask } = useContext(TasksContext)

  const [sent, setSent] = useState(false)
  const [error, setError] = useState(null)
  const [sending, setSending] = useState(false)
  const [number, setNumber] = useState('')

  const functionURL = 'https://my-dtc-6530.twil.io/send-sms'

  const handleSendSMS = async (event) => {
    event.preventDefault()
    setSending(true)
    const sendTasks = tasks.filter(task => !task.done)
    const sendTasksString = sendTasks.map(task => `\n◻ ${task.name}`).join('')
    const to = number
    const body = 'Hi! Here are your remaining tasks for the DTC:' + sendTasksString

    const response = await fetch(functionURL, {
      method: "post",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams({ to, body }).toString(),
    })
    if (response.status === 200) {
      setSending(false)
      setNumber('')
      setSent(true)
    } else {
      const json = await response.json()
      setError(json.error)
      setSending(false)
    }
  }

  const handleGeneratePDF = () => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    let responseHTML = document.getElementById('tasks-list').innerHTML
    let formattedResponseHTML = htmlToPdfmake(responseHTML);
    let docDefinition = {
      info: {
        title: "My DTC Tool",
        author: "Disability Alliance BC"
      },
      content: [
        formattedResponseHTML
      ],
      styles: {
        'html-h4': {
          marginTop: 12
        },
        'html-table': {
          fontSize: 13,
          borders: 0
        }
      }
    }
    pdfMake.createPdf(docDefinition).download('My_DTC_Tasks.pdf')
  }

  const TasksListPDF = () => {
    const toDoTasks = tasks.filter(task => !task.done)
    const doneTasks = tasks.filter(task => task.done)
  
    return (
      <>
        {toDoTasks.length > 0 &&
        <>
        <h5>To do</h5>
        <hr />
        <table border={0} data-pdfmake="{&quot;widths&quot;:[20,&quot;*&quot;],&quot;heights&quot;:20,&quot;layout&quot;:&quot;noBorders&quot;}">
          <tbody>
            {toDoTasks.map(task => (
              <tr key={task.id}>
                <td><img height="20" width="20" src={unchecked} alt="Unchecked" /></td>
                <td>{task.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        </>
        }
        {doneTasks.length > 0 &&
        <>
        <h5>Done</h5>
        <hr />
        <table border={0} data-pdfmake="{&quot;widths&quot;:[20,&quot;*&quot;],&quot;heights&quot;:20,&quot;layout&quot;:&quot;noBorders&quot;}">
          <tbody>
            {doneTasks.map(task => (
              <tr key={task.id}>
                <td><img height="20" width="20" src={checked} alt="Checked" /></td>
                <td><strike>{task.name}</strike></td>
              </tr>
            ))}
          </tbody>
        </table>
        </>
        }
      </>
    );
  }
  return (
    <>
      <section className={!onPage ? 'tasks text-sm mb-4' : 'tasks on-page mb-4 p-3'}>
        {!onPage && <header className="mb-2 flex items-center justify-between"><h2 className="pt-0 mb-3">My DTC tasks</h2><Link to="/tasks" className="btn btn-accent btn-sm mb-3 ml-2">More info</Link></header>}
        <TasksList
          tasks={tasks}
          onChangeTask={handleChangeTask}
          onDeleteTask={handleDeleteTask}
        />
        <TasksAdd onAddTask={handleAddTask} />
      </section>
      <Expander header="View some example tasks">
        <ul className="mb-3 !pl-0">
          {defaultTasks.map((defaultTask, index) => (
            <li className="flex items-center gap-3" key={index}><button className="btn btn-sm btn-outline hover:text-black" onClick={() => handleAddTask(defaultTask.name)}>Add <PlusIcon aria-hidden="true" /></button> {defaultTask.name}</li>
          ))}
        </ul>
        <button className="mt-3 mb-1 btn btn-outline hover:text-black" onClick={handleDefaultTasks}>Add all <PlusIcon aria-hidden="true" /></button>
      </Expander>
      <Expander header="Get a copy of your tasks">
      <div className="sm:join tasks w-full mb-3 mr-3">
        <button className="btn btn-outline hover:text-black join-item mb-2 mr-2 sm:mr-0" onClick={handleGeneratePDF}>Download PDF <DownloadIcon className="large-icon" aria-hidden="true" /></button>
        <Dialog.Root>
          <Dialog.Trigger className="btn btn-outline hover:text-black join-item">
            Send by text <ChatBubbleIcon className="large-icon" aria-hidden="true" />
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent">
              <Dialog.Title className="DialogTitle !text-xl pt-0">
                Send your tasks by text
              </Dialog.Title>
              <Dialog.Description className="DialogDescription">
                <ExclamationTriangleIcon className="inline-block" aria-hidden="true" /> Privacy warning: using this feature shares your phone number with a a third-party platform called Twilio. Please review our <Link to="/privacy-policy-and-terms-conditions" className="link">privacy policy & terms and conditions</Link> prior to use.
              </Dialog.Description>
              {error && <div className="mt-4 alert alert-error">Error: {error.status} | {error.code} | {error.moreInfo}</div>}
              {sending && <div className="mt-4 alert alert-info">Sending...</div>}
              {sent && <div className="mt-4 alert alert-success">Sent!</div>}
              <Form.Root 
                className="border border-secondary rounded-lg pt-2 pb-4 px-4 mt-6 mb-2"
                method="post"
                action={functionURL}
              >
                <Form.Field name="tel">
                  <Form.Label className="my-2 block">Enter your number</Form.Label>
                  <Form.Message match="valueMissing" className="block mb-2 alert alert-warning">
                    Please enter your phone number
                  </Form.Message>
                  <Form.Message match="patternMismatch" className="block mb-2 alert alert-warning">
                    Please enter your number in the format 123-456-7890 (with dashes)
                  </Form.Message>
                  <Form.Control asChild>
                    <input 
                      className="input"
                      type="tel" 
                      placeholder="123-456-7890"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      maxLength={12}
                      onChange={(event) => setNumber(event.target.value)}
                      required 
                    />
                  </Form.Control>
                </Form.Field>
                <Form.Submit asChild>
                  <button className="btn !bg-secondary mt-2 !w-24 !text-white" onClick={handleSendSMS}>
                    Send
                  </button>
                </Form.Submit>
              </Form.Root>
              <Dialog.Close asChild className="DialogClose">
                <button aria-label="Close">
                  <Cross2Icon className="large-icon" aria-hidden="true" />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      <div className="hidden" id="tasks-list">
        <BaseLogo />
        <h4>My DTC Tasks</h4>
        <hr />
        <TasksListPDF />
      </div>
      </Expander>
    </>
  )
}

export default Tasks