import React from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import {
  ChevronDownIcon
} from '@radix-ui/react-icons'

const Expander = ({ clear, header, id, children }) => (
  <Accordion.Root type="single" collapsible className={clear ? 'expander clear' : 'expander'}>
    <Accordion.Item value={id ? id : header}>
      <Accordion.Trigger>
        <ChevronDownIcon className="transition-all large-icon" aria-hidden="true" />{header}
      </Accordion.Trigger>
      <Accordion.Content>
        {children}
      </Accordion.Content>
    </Accordion.Item>
  </Accordion.Root>
)
export default Expander