import React, { useContext } from 'react'
import { UserResponsesContext } from '../../contexts/contexts'

const ShowIf = ({ condition, children }) => {

  const { userResponses } = useContext(UserResponsesContext)
  // Get the key and value from the condition object
  const conditionKey = Object.keys(condition)[0]
  const conditionValue = Object.values(condition)[0]

  // Check if userResponses has a key that matches conditionKey
  let checkCondition
  if (conditionKey) {
    checkCondition = userResponses?.[conditionKey]
  }

  if (Array.isArray(conditionValue)) {
    if (checkCondition !== undefined && (checkCondition === conditionValue[0] || checkCondition === conditionValue[1])) {
      return <section>{children}</section>
    }
  } else {
    if (checkCondition !== undefined && checkCondition === conditionValue) {
      return <section>{children}</section>
    }
  }
  return null
}

export default ShowIf