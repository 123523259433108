import React, { useContext } from 'react'
import * as ToggleGroup from '@radix-ui/react-toggle-group'
import {
  CircleIcon,
  CheckCircledIcon
} from '@radix-ui/react-icons'
import { UserResponsesContext } from '../../contexts/contexts'
import { useHandleValueChange } from '../../hooks/use-handle-value-change'

const QuestionMultiSelect = ({ restriction, generates, options, other, children }) => {

  const { userResponses } = useContext(UserResponsesContext)
  const handleValueChange = useHandleValueChange(restriction, generates)

  // Get the stored values for this question, if they exist
  const toggleGroupValue = userResponses?.restrictionsResponses?.[restriction]?.[generates]
  const otherObject = toggleGroupValue?.find(response => typeof response === 'object' && response.other)

  return (
    <>
      {children}
      <ToggleGroup.Root
        value={toggleGroupValue || []}
        type="multiple"
        onValueChange={(value) => handleValueChange(value)}
      >
        {
          options.map((option, index) => {
          // If the option is an array rather than a string, then we want to output the 2nd item in the array in the generated PDF, but use the first item for the user-facing labels.
          const isArray = Array.isArray(option)
            return (
              <ToggleGroup.Item className="button nav" key={index} value={isArray ? option[1] : option}>
                {isArray ? option[0] : option}
                <CircleIcon className="large-icon unchecked" aria-hidden="true" />
                <CheckCircledIcon className="large-icon checked" aria-hidden="true" />
              </ToggleGroup.Item>
            )
          })
        }
      </ToggleGroup.Root>
      {
      other != null ?
        other.type === 'multi' ?
          <label className="form-control" id="textarea-label">
            <div className="label">
              <strong>{other.label ? other.label : 'Other'}</strong>
            </div>
            <textarea
              aria-labelledby="textarea-label"
              value={otherObject?.value || ''}
              onChange={event => handleValueChange({
                label: other.label ? other.label : 'Other',
                value: event.target.value,
                other: true,
                required: 'both'
              })}
              placeholder={other.placeholder ? other.placeholder : 'Type here'}
              className="textarea textarea-secondary bg-white"
              rows={5}
            />
          </label> :
          <label className={(otherObject?.other && otherObject?.value !== '') ? 'inset-input nav active' : 'inset-input nav'} id="input-label">
            {other.label ? other.label : 'Other'}
            <input
              aria-labelledby="input-label"
              value={otherObject?.value || ''}
              type="text"
              onChange={event => handleValueChange({
                label: other.label ? other.label : 'Other',
                value: event.target.value,
                other: true,
                required: 'both'
              })}
              className="grow"
              placeholder="Type here"
            />
            <CircleIcon className="large-icon unchecked" aria-hidden="true" />
            <CheckCircledIcon className="large-icon checked" aria-hidden="true" />
          </label> :
        null
      }
    </>
  )
}

export default QuestionMultiSelect