import React, { useContext, useEffect, useState } from 'react'
import {
  CrossCircledIcon,
  PlusCircledIcon
} from '@radix-ui/react-icons';
import { UserResponsesContext } from '../../contexts/contexts'
import { useUpdateUserResponses } from '../../hooks/use-update-user-responses'

const QuestionConditionsYears = ({ restriction, generates, children }) => {

  const { userResponses } = useContext(UserResponsesContext)
  const updateUserResponses = useUpdateUserResponses()

  // keep track of number of inputs and their values
  const [inputs, setInputs] = useState([{ label: '', value: '' }])

  // check if values exist
  useEffect(() => {
    const conditionsValue = userResponses?.restrictionsResponses?.[restriction]?.[generates] ? userResponses?.restrictionsResponses?.[restriction]?.[generates] : null;
    if (Array.isArray(conditionsValue) && conditionsValue.length > 0) {
      setInputs(conditionsValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // run when inputs changes
  useEffect(() => {
    updateUserResponses(restriction, generates, inputs)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs])

  const handleInputChange = (event, index, inputKey) => {
    const newInputs = [...inputs]
    newInputs[index][inputKey] = event.target.value
    setInputs(newInputs)
  }

  const addInputPair = () => {
    setInputs([...inputs, { label: '', value: '' }])
  }

  const removeInputPair = (index) => {
    setInputs(inputs.filter((_, i) => i !== index))
  }

  return (
    <>
      {children}
      {inputs.map((input, index) => (
        <div key={index} className="flex-wrap sm:flex-nowrap join w-full items-end border border-secondary p-3 pt-0 mb-2 sm:border-0 sm:p-0 sm:mb-0">
          <label className="form-control w-full">
            <div className="label text-sm">
              Condition
            </div>
            <input
              value={input.label}
              onChange={event => handleInputChange(event, index, 'label')}
              type="text"
              className="input input-secondary bg-white sm:join-item !rounded-l-lg"
            />
          </label>
          <label className="form-control w-32">
            <div className="label text-sm">
              Year (YYYY)
            </div>
            <input
              value={input.value}
              onChange={event => handleInputChange(event, index, 'value')}
              type="number"
              className="input input-secondary bg-white sm:join-item"
              min={1924}
              max={2044}
              step="1"
              pattern="[0-9]{4}"
              size="4"
            />
          </label>
          <button aria-label="Remove" className="btn border-secondary sm:join-item self-end ml-2 sm:ml-0" onClick={() => removeInputPair(index)}><CrossCircledIcon className="large-icon" aria-hidden="true" /></button>
        </div>
      ))}
      <hr className="mb-0" />
      <button
        className="btn btn-secondary add-task"
        aria-label="Add task"
        onClick={addInputPair}
      >Add condition<PlusCircledIcon className="large-button" aria-hidden="true" />
      </button>
    </>
  )
}

export default QuestionConditionsYears