import React, { useContext } from 'react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { UserResponsesContext } from '../../contexts/contexts'

const QuestionLstCheck = ({ children }) => {

  const { userResponses, setUserResponses } = useContext(UserResponsesContext)

  // Get the stored value, if it exists
  const selectedValue = userResponses?.requiresLst

  const handleValueChange = (newResponse) => {
    if (newResponse !== undefined) {
      setUserResponses(prevUserResponses => ({ ...prevUserResponses, requiresLst: newResponse}))
    }
  }

  return (
    <>
    {children}
    <RadioGroup.Root
      aria-label="Choose yes or no"
      value={selectedValue}
      className="flex flex-wrap gap-4 sm:gap-6"
      onValueChange={(value) => {
        handleValueChange(value)
      }}
    >
      <div className="flex items-center">
        <RadioGroup.Item id={`lst-yes`} value="yes" className="radio-item large-icon">
          <RadioGroup.Indicator className="radio-indicator" />
        </RadioGroup.Item>
        <label className="button nav" htmlFor={`lst-yes`}>
          Yes
        </label>
      </div>
      <div className="flex items-center">
        <RadioGroup.Item id={`lst-no`} value="no" className="radio-item large-icon">
          <RadioGroup.Indicator className="radio-indicator" />
        </RadioGroup.Item>
        <label className="button nav" htmlFor={`lst-no`}>
          No
        </label>
      </div>
    </RadioGroup.Root>
    </>
  )
}

export default QuestionLstCheck