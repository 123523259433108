import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {
  HeartIcon,
  HomeIcon
} from '@radix-ui/react-icons'
import { links } from '../../content/constants'

const SiteNavigationList = () => {

  // query all pages and their directories
  const pages = useStaticQuery(graphql`
    query {
      allMdx {
        edges {
          node {
            frontmatter {
              slug
              shortTitle
              inMenu
              menuHeading
              menuOrder
            }
          }
        }
      }
    }
  `)

  // filter pages to only include those that are marked inMenu in the frontmatter
  const filteredPages = pages.allMdx.edges.filter(edge => edge.node.frontmatter.inMenu)

  const menuOrder = ['Step-by-Step Guide for individuals', 'Step-by-Step Guide for medical practitioners', 'Tools to help you apply for the DTC', 'General info']

  // group pages by menuHeading and sort them by menuOrder
  const groupedPages = filteredPages.reduce((acc, page) => {
    const menuHeading = page.node.frontmatter.menuHeading || 'Main';
    if (!acc[menuHeading]) {
      acc[menuHeading] = [];
    }
    acc[menuHeading].push(page);
    acc[menuHeading].sort((a, b) => (a.node.frontmatter.menuOrder || 10) - (b.node.frontmatter.menuOrder || 10));
    return acc;
  }, {});
     
  return (
    <section className="text-sm">
      <header className="flex items-center gap-x-3">
        <h2 className="pt-0 mb-0 hidden sm:inline-block">Menu</h2>
        <Link className="sm:hidden" to="/">
          <StaticImage
            alt="Disability Alliance BC"
            src="../../images/dabc-logo.svg"
            width={90}
            placeholder="none"
          />
        </Link>
        <Link to="/">Home <HomeIcon className="ml-1 hidden sm:inline-block" aria-hidden="true" /></Link>
        <a className="md:hidden text-center" href={links.donate} target="_blank" rel="noreferrer">Support us <HeartIcon className="ml-1 hidden sm:inline-block" aria-hidden="true" /></a>
      </header>
      <nav>
        {
          menuOrder.map(menuHeading => (
            <div key={menuHeading} className="mt-2">
              <h3 className="pt-2 text-sm">{menuHeading}</h3>
              <ul className="pl-2 ml-1 border-l border-neutral-500">
              {groupedPages[menuHeading].map(page => (
                <li key={page.node.frontmatter.slug} className="mb-1"><Link to={page.node.frontmatter.slug}>{page.node.frontmatter.shortTitle}</Link></li>
              ))}
              </ul>
            </div>
          ))
        }
      </nav>
    </section>
  )
}

export default SiteNavigationList