import { useCallback } from 'react'
import { useUpdateUserResponses } from './use-update-user-responses'

// take in the restriction and 'generates' (or key) value
export const useHandleValueChange = (restriction, generates) => {
  const updateUserResponses = useUpdateUserResponses()

  return useCallback((newValue) => {
    if (restriction && generates && newValue !== undefined) {
      updateUserResponses(restriction, generates, newValue)
    }
  }, [updateUserResponses, restriction, generates])
}