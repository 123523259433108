export const info = {
  name: "Disability Alliance BC",
  description: "Since 1977, Disability Alliance BC has been a provincial, cross-disability voice in British Columbia.",
  siteLastUpdated: "Updated for 2024 (tax year 2023)",
  noteOnPastYears: "Remember that the DTC can be applied for going back up to ten years. So if, for example, you’re not restricted today but were restricted for the past three years, you may still be eligible.",
  dtcAmount: {
    federal: 9872,
    federalChild: 5758,
    bc: 9435,
    bcChild: 5505
  },
  taxRates: {
    federalLowest: 15,
    bcLowest: 5.06
  }
}

export const links = {
  home: 'https://mydtc.dabc.ca',
  dabc: 'https://disabilityalliancebc.org',
  contact: 'https://disabilityalliancebc.org/contact-us-new-looking',
  donate: 'https://disabilityalliancebc.org/support-us',
  about: 'https://disabilityalliancebc.org/about-dabc',
  privacy: 'https://mydtc.dabc.ca/privacy-policy-and-terms-conditions',
  dabcTollFree: '1-800-663-1278',
  dabcPhone: '604-872-1278',
  dabcEmail: 'rdsp@dabc.ca',
}