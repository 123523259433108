import React, { useState } from 'react'
import * as Checkbox from '@radix-ui/react-checkbox'
import {
  CheckIcon,
  CrossCircledIcon,
} from '@radix-ui/react-icons';

const Task = ({ task, onChange, onDelete }) => {

  const [editing, setEditing] = useState(false)
  const checked = task.done
  let taskContent

  if (editing) {
    taskContent = (
      <input
        aria-label="Task"
        value={task.name}
        className="px-1 input input-sm input-bordered w-full"
        onChange={event => {
          onChange({
            ...task,
            name: event.target.value
          })
        }}
      />
    )
  } else {
    taskContent = (
      task.name
    )
  }
  return (
    <>
      <div className="w-full flex items-center gap-2">
      <Checkbox.Root className="checkbox-box" id={`task-${task.id}`} checked={task.done} onCheckedChange={
        checked => {
          onChange({
            ...task,
            done: checked
          })
        }
      }>
        <Checkbox.Indicator>
          <CheckIcon aria-hidden="true" />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label htmlFor={`task-${task.id}`} className="select-none w-full cursor-pointer flex mr-2">
        {taskContent}
      </label>
      </div>
      {editing ?
        <button className="btn btn-sm border border-neutral-400 rounded-lg sm:ml-auto" onClick={() => setEditing(false)}>Save</button> :
        <button className="btn btn-sm border border-neutral-400 rounded-lg sm:ml-auto" onClick={() => setEditing(true)}>Edit</button>
      }
      <button className="btn btn-sm border border-neutral-400 rounded-lg" onClick={() => onDelete(task.id)} aria-label="Delete task"><CrossCircledIcon className="large-icon" aria-hidden="true" /></button>
    </>
  )
}

export default Task



