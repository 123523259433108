import React, { useContext } from 'react'
import * as RadioGroup from '@radix-ui/react-radio-group'
import { UserResponsesContext } from '../../contexts/contexts'
import { useHandleValueChange } from '../../hooks/use-handle-value-change'
import { v4 as uuidv4 } from 'uuid'

const QuestionSingleSelect = ({ update, type, restriction, generates, options, other, children }) => {

  const { userResponses, setUserResponses } = useContext(UserResponsesContext)
  const handleValueChange = useHandleValueChange(restriction, generates)

  // since we're using HTML ids to associated labels to radio items, use this to generate unique ids for each one in case there are multiple questions on the same page
  const id = uuidv4()

  // Get the stored values for this question, if they exist
  const selectedValue = userResponses?.restrictionsResponses?.[restriction]?.[generates]
  const [radioValue, textValue] = selectedValue ? selectedValue.split(' — ') : ['', '']

  // If there's an update object, set based on the selected value
  const handleUpdate = (value, update) => {
    if (typeof update?.key === 'string' && value) {
      let val = value.toLowerCase()
      setUserResponses(prevUserResponses => ({ ...prevUserResponses, [update.key]: update[val] }))
      const element = document.getElementById('scroll-to')
      // Only run if we're at less than the tailwind 'lg' size
      if (element && window.innerWidth < 1024) {
        const timer = setTimeout(() => {
          element.scrollIntoView()
        }, 100)
        return () => clearTimeout(timer)
      }
    }
  }

  if (type === 'yesno') {

    return (
      <>
        {children}
        <RadioGroup.Root
          aria-label="Choose yes or no"
          value={selectedValue}
          onValueChange={(value) => {
            handleValueChange(value)
            handleUpdate(value, update)
          }}
        >
          <div className="flex items-center">
            <RadioGroup.Item id={`${id}-yes`} value="Yes" className="radio-item large-icon" aria-hidden="true">
              <RadioGroup.Indicator className="radio-indicator" />
            </RadioGroup.Item>
            <label className="button nav" htmlFor={`${id}-yes`}>
              Yes
            </label>
          </div>
          <div className="flex items-center">
            <RadioGroup.Item id={`${id}-no`} value="No" className="radio-item large-icon" aria-hidden="true">
              <RadioGroup.Indicator className="radio-indicator" />
            </RadioGroup.Item>
            <label className="button nav" htmlFor={`${id}-no`}>
              No
            </label>
          </div>
        </RadioGroup.Root>
      </>
    )
  }
  else {
    return (
      <>
        {children}
        {options &&
          <RadioGroup.Root
            aria-label="Choose one response"
            value={radioValue || ''}
            onValueChange={handleValueChange}
          >
            {
              options.map((option, index) => {
                const isArray = Array.isArray(option)
                return (
                  <React.Fragment key={index}>
                    <div className="flex items-center">
                      <RadioGroup.Item id={`${id}-${index}`} value={isArray ? option[1] : option} className="radio-item large-icon">
                        <RadioGroup.Indicator className="radio-indicator" />
                      </RadioGroup.Item>
                      <label className="button nav" htmlFor={`${id}-${index}`}>
                        {isArray ? option[0] : option}
                      </label>
                    </div>
                    {other && (option === radioValue && other.forOption === index + 1) &&
                      <>
                        {other.label ? other.label : 'Please explain:'}
                        {other.type === 'year' &&
                          <input
                            value={textValue || ''}
                            placeholder="YYYY"
                            onChange={event => {
                              handleValueChange(`${radioValue} — ${event.target.value}`)
                            }}
                            type="number"
                            className="block input input-secondary bg-white w-32 mt-3"
                            min={1924}
                            max={2044}
                            step="1"
                            pattern="[0-9]{4}"
                            size="4"
                          />
                        }
                      </>
                    }
                  </React.Fragment>
                )
              })
            }
          </RadioGroup.Root>
        }
      </>
    )
  }
}

export default QuestionSingleSelect