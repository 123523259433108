import React from 'react'
import {
  CheckCircledIcon
} from '@radix-ui/react-icons';
import { info } from '../../content/constants'

const LastUpdated = () => {
  return (
  <p className="text-sm"><CheckCircledIcon className="mr-1 inline-block align-text-bottom" aria-hidden="true" />{info.siteLastUpdated}</p>
)}

export default LastUpdated