import React from 'react'
import Task from './task'

const TasksList = ({ tasks, onChangeTask, onDeleteTask }) => (
  tasks.map(task => (
    <div className="task" key={task.id}>
      <Task
        task={task}
        onChange={onChangeTask}
        onDelete={onDeleteTask}
      />
    </div>
  ))
)

export default TasksList