import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Link } from 'gatsby'

import SiteNavigation from './main/site-navigation'
import SiteFooter from './main/site-footer'
import Sidebar from './main/sidebar'
import Question from './main/question'
import { NavItem as Nav } from './main/nav-item'

import ActivitiesCalculator from './sub/activities-calculator'
import Card from './sub/card'
import LastUpdated from './sub/last-updated'
import InfoPanel from './sub/info-panel'
import WarningPanel from './sub/warning-panel'
import PrivacyWarning from './sub/privacy-warning'
import Expander from './sub/expander'
import ShowIf from './sub/show-if'

import {
  QuestionMarkCircledIcon as Qm,
  ExclamationTriangleIcon as Em
} from '@radix-ui/react-icons'

import { TasksProvider, UserNavHistoryContext, UserResponsesContext, UserSettingsContext } from '../contexts/contexts'

const shortCodes = { ActivitiesCalculator, Card, Em, Expander, ShowIf, InfoPanel, Link, Nav, PrivacyWarning, Qm, Question, WarningPanel }

const defaultSettings = {
  displaySize: 'medium',
  theme: 'dabc',
  fontName: 'lexend',
  language: 'en'
}

const Layout = ({ children, location, pageContext }) => {

  const [showAlert, setShowAlert] = useState(false)
  const [visitedPages, setVisitedPages] = useState([])
  const [userSettings, setUserSettings] = useState(defaultSettings)
  const [userResponses, setUserResponses] = useState({
    canSee: 'unset',
    canSpeak: 'unset',
    difficultyEating: 'unset',
    difficultyCooking: 'unset',
    requiresLst: 'unset',
    consent: 'unset',
    completed: 'unset',
    selectedRestrictions: [],
    restrictionResponses: {}
  })
  const pageHeadingRef = useRef()

  // only update if one of the values changes not on every render
  const navProviderValue = useMemo(() => ({ visitedPages, setVisitedPages }), [visitedPages, setVisitedPages])
  const responsesProviderValue = useMemo(() => ({ userResponses, setUserResponses }), [userResponses, setUserResponses])
  const settingsProviderValue = useMemo(() => ({ userSettings, setUserSettings }), [userSettings, setUserSettings])

  // user preference options
  useEffect(() => { document.querySelector('html').setAttribute('data-size', userSettings.displaySize) }, [userSettings.displaySize])
  useEffect(() => { document.querySelector('html').setAttribute('data-theme', userSettings.theme) }, [userSettings.theme])
  useEffect(() => { document.querySelector('html').setAttribute('data-font', userSettings.fontName) }, [userSettings.fontName])
  useEffect(() => { document.querySelector('html').setAttribute('lang', userSettings.language) }, [userSettings.language])

  useEffect(() => {
    document.querySelector('html').setAttribute('data-page', location.pathname)
    if (pageHeadingRef.current) {
      const timer = setTimeout(() => {
        pageHeadingRef.current.focus()
      }, 250) // delay by 250 ms
      // Cleanup function to clear the timer when the component unmounts or updates
      return () => clearTimeout(timer)
    }
  }, [location])

  return (
    <UserSettingsContext.Provider value={settingsProviderValue}>
      {showAlert &&
        <div className="px-8">
          <div role="alert" className="mx-auto max-w-7xl alert alert-warning my-4 w-auto">
            <Em className="large-icon" />
            <span>This tool is in development and is only meant to be used for testing purposes. All content and optimization is still in progress.</span>
            <div>
              <button className="btn btn-sm" onClick={() => setShowAlert(false)}>Close this warning</button>
            </div>
          </div>
        </div>
      }
      <TasksProvider>
        <SiteNavigation currentLocation={location.pathname} />
        <main className="flex flex-wrap lg:flex-nowrap max-w-7xl mx-auto">
          <UserNavHistoryContext.Provider value={navProviderValue}>
            <Sidebar
              currentShortTitle={pageContext?.frontmatter?.shortTitle || ''}
              currentLocation={location.pathname}
            />
          </UserNavHistoryContext.Provider>
          <article className="w-full order-1 lg:order-2 max-w-3xl p-3 xs:p-4 sm:p-8 lg:px-16">
            <header className="mb-3">
              <div tabIndex={-1} ref={pageHeadingRef}>
                <h1>{pageContext?.frontmatter?.title || ''}</h1>
                {location.pathname === '/' && <LastUpdated />}
              </div>
            </header>
            <UserResponsesContext.Provider value={responsesProviderValue}>
            <MDXProvider components={shortCodes}>
              {children}
            </MDXProvider>
            </UserResponsesContext.Provider>
          </article>
        </main>
      </TasksProvider>
      <SiteFooter />
    </UserSettingsContext.Provider>
  )
}

export default Layout