import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import {
  CircleIcon,
  PlusCircledIcon,
  HomeIcon
} from '@radix-ui/react-icons'

import HomePageImg from '../sub/home-page-img'

import { UserNavHistoryContext } from '../../contexts/contexts'

const Sidebar = ({ currentLocation, currentShortTitle }) => {

  const { visitedPages, setVisitedPages } = useContext(UserNavHistoryContext)
  const [ showMore, setShowMore ] = useState(false)

  useEffect(() => {
    setVisitedPages(prevVisitedPages => [
      {
        title: currentShortTitle,
        location: currentLocation
      },
      ...prevVisitedPages // old items to the end
    ].slice(0, 11))
  }, [currentLocation, currentShortTitle, setVisitedPages])

  const handleShowMore = () => {
    setShowMore(!showMore)
  }

  return (
    currentLocation === '/' ?
    <HomePageImg /> :
    <div className="order-2 lg:order-1 text-sm p-6 bg-secondary text-accent w-full min-w-[280px] lg:w-[300px] lg:min-h-[500px] lg:mb-4 lg:rounded-r-lg xl:rounded-lg border-b-2 self-start">
      <Link to="/" className="text-xs mb-3 inline-block underline">Go home<HomeIcon className="inline-block ml-1" aria-hidden="true" /></Link>
      <h4 className="mb-3 p-0">Page navigator</h4>
      <span className="text-xs block my-1">You are here</span>
      <ul className="timeline timeline-vertical timeline-compact text-neutral-50">
        <li>
          <hr />
          <div className="timeline-middle">
            <CircleIcon aria-hidden="true" />
          </div>
          <div className="timeline-end">{currentShortTitle}</div>
          {visitedPages.length > 1 && <hr /> }
        </li>
      </ul>
      {visitedPages.length > 1 &&
        <>
          <span className="text-xs block my-1">Go back to</span>
          <ul className="timeline timeline-vertical timeline-compact text-neutral-50">
            {visitedPages.map((prevPage, index) => {
              if (index > 0 && (index < 6 || showMore)) {
                return (
                  <li key={'prev-' + index}>
                    <hr />
                    <div className="timeline-middle">
                      <CircleIcon aria-hidden="true" />
                    </div>
                    <div className="timeline-end">
                      {prevPage.location !== currentLocation ?
                        <Link to={prevPage.location}>{prevPage.title}</Link> :
                        <span className="opacity-65">{prevPage.title}</span>
                      }
                    </div>
                    {index < 10 && <hr />}
                  </li>
                )
              }
              if (index === 6 && !showMore) {
                return (
                  <li key={'prev-' + index}>
                    <hr />
                    <div className="timeline-middle">
                      <PlusCircledIcon aria-hidden="true" />
                    </div>
                    <div className="timeline-end"><button onClick={handleShowMore}>Show more</button></div>
                  </li>
                )
              }
              return null
            })}
          </ul>
        </>
      }
    </div>
  )
}

export default Sidebar