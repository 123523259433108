import React from 'react'
import { Link, Script } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { links } from '../../content/constants'
import {
    QuestionMarkCircledIcon as Qm
  } from '@radix-ui/react-icons'

const SiteFooter = () => {
  return (
    <>
      <footer className="bg-secondary text-neutral-200 px-4 py-6 sm:p-6">
          <div className="lg:flex justify-between items-center mx-auto max-w-7xl">
            <p className="mb-0 mr-4 max-w-prose"><Qm className="text-center inline-block align-middle mr-2" aria-hidden="true" />My DTC was created by <a className="link" rel="noreferrer" target="_blank" href={links.dabc}>Disability Alliance BC</a> in partnership with <a rel="noreferrer" target="_blank" className="link" href="https://planinstitute.ca/resources/access-rdsp/">Access RDSP</a> and <a rel="noreferrer" target="_blank" className="link" href="https://finautonome.org">Finautonome</a>. For support by phone or email, you can <Link className="link" to="/community/contact/">contact DABC</Link>.</p>
            <div className="mt-3 flex items-center gap-8 py-2">
              <a rel="noreferrer" target="_blank" className="link" href="https://planinstitute.ca/resources/access-rdsp/">
                <StaticImage
                  alt="Access RDSP"
                  src="../../images/access-rdsp.png"
                  width={200}
                  placeholder="none"
                />
              </a>
              <a rel="noreferrer" target="_blank" className="link" href="https://finautonome.org">
                <StaticImage
                  alt="Finautonome"
                  src="../../images/Finautonome_Logo_Aubergine.png"
                  width={150}
                  placeholder="none"
                />
              </a>
            </div>
          </div>
          <p className="mt-2 text-sm mx-auto max-w-7xl opacity-80">&copy; {new Date().getFullYear()} Disability Alliance BC | <Link to="/community" className="link">Share your Feedback</Link> | <Link to="/privacy-policy-and-terms-conditions" className="link">Privacy Policy & Terms and Conditions</Link></p>
      </footer>
      <Script 
        src='https://static.cloudflareinsights.com/beacon.min.js'
        strategy='post-hydrate'
        data-cf-beacon='{"token": "996096205aa248a9a06bcd347eef7df3"}' 
      />
    </>
  )
}

export default SiteFooter