import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {
  CheckboxIcon,
  FontSizeIcon,
  HamburgerMenuIcon,
} from '@radix-ui/react-icons'

import Tasks from './tasks'
import Settings from './settings'
import SiteNavigationList from './site-navigation-list'

import { links } from '../../content/constants'

const SiteNavigation = ({currentLocation}) => {

  const [value, setValue] = useState('')

  const handleClose = (currentTarget) => {
    if (!currentTarget.classList.contains('toolbar-btn')) {
      setValue('')
    }
  }

  // When location changes, close the menu. By default it stays open.
  useEffect(() => {
    setValue('')
  }, [currentLocation])

  return (
    <header className="px-4 py-6 bg-secondary sm:px-6 sm:bg-transparent lg:flex flex-wrap items-center z-10 relative mx-auto max-w-7xl">
      <NavigationMenu.Root className="nav-menu-root" value={value} onValueChange={setValue}>
        <NavigationMenu.List className="nav-menu-list justify-center sm:justify-end">
          <NavigationMenu.Item className="hidden mr-auto sm:inline-block md:mr-4">
            <Link className="block" to="/">
              <StaticImage
                alt="Disability Alliance BC"
                src="../../images/dabc-logo.svg"
                width={100}
                placeholder='none'
              />
            </Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item className="hidden md:inline-block">
            <NavigationMenu.Link className="toolbar-btn" href={links.donate} target="_blank" rel="noreferrer">
              Support us
            </NavigationMenu.Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item value="todo" className="md:ml-auto">
            <NavigationMenu.Trigger
              className="toolbar-btn"
              onPointerMove={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
              aria-label="My tasks"
            >
              <span className="hidden sm:inline-block pointer-events-none" aria-hidden="true">My tasks</span><CheckboxIcon aria-hidden="true" className="pointer-events-none" />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content
              className="nav-menu-content"
              onPointerEnter={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
              onInteractOutside={(event) => event.preventDefault()}
              onPointerDownOutside={(event) => handleClose(event.currentTarget)}
            >
              <Tasks />
            </NavigationMenu.Content>
          </NavigationMenu.Item>
          <NavigationMenu.Item value="settings">
            <NavigationMenu.Trigger
              className="toolbar-btn"
              onPointerMove={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
              aria-label="Settings"
            >
              <span className="hidden sm:inline-block pointer-events-none" aria-hidden="true">Settings</span><FontSizeIcon aria-hidden="true" className="pointer-events-none" />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content
              className="nav-menu-content"
              onPointerEnter={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
              onInteractOutside={(event) => event.preventDefault()}
              onPointerDownOutside={(event) => handleClose(event.currentTarget)}
            >
              <Settings />
            </NavigationMenu.Content>
          </NavigationMenu.Item>
          <NavigationMenu.Item value="menu" className="ml-auto sm:ml-0">
            <NavigationMenu.Trigger
              className="toolbar-btn"
              onPointerMove={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
              aria-label="Menu"
            >
              <span className="hidden xs:inline-block pointer-events-none" aria-hidden="true">Menu</span ><HamburgerMenuIcon aria-hidden="true" className="pointer-events-none" />
            </NavigationMenu.Trigger>
            <NavigationMenu.Content
              className="nav-menu-content"
              onPointerEnter={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
              onInteractOutside={(event) => event.preventDefault()}
              onPointerDownOutside={(event) => handleClose(event.currentTarget)}
            >
              <SiteNavigationList />
            </NavigationMenu.Content>
          </NavigationMenu.Item>
          <NavigationMenu.Indicator className="nav-menu-indicator">
            <div className="arrow" />
          </NavigationMenu.Indicator>
        </NavigationMenu.List>
        <div className="nav-viewport-position">
          <NavigationMenu.Viewport className="nav-menu-viewport" />
        </div>
      </NavigationMenu.Root>
    </header>
  )
}

export default SiteNavigation