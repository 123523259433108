import React, { useEffect, useState } from 'react'
import {
  CrossCircledIcon,
  PlusCircledIcon
} from '@radix-ui/react-icons';

const ActivitiesCalculator = () => {

  const initialActivities = [{
    id: 0,
    text: '',
    minutes: ''
  }]

  const [activitiesList, setActivitiesList] = useState(initialActivities)
  const [activityId, setActivityId] = useState(initialActivities.length)
  const [totalMins, setTotalMins] = useState(0)
  const [totalHoursMins, setTotalHoursMins] = useState('')

  const handleAddActivity = (activity) => {
    if (activity !== '') {
      const newActivity = {
        id: activityId,
        text: '',
        minutes: ''
      };
      setActivitiesList([...activitiesList, newActivity])
      setActivityId(activityId + 1)
    }
  }

  const handleValueChange = (id, newResponse, minutes) => {
    minutes ?
    setActivitiesList(prevActivitiesList =>
      prevActivitiesList.map(activity =>
        activity.id === id ? { ...activity, minutes: Number(newResponse) } : activity
      )
    ) :
    setActivitiesList(prevActivitiesList =>
      prevActivitiesList.map(activity =>
        activity.id === id ? { ...activity, text: newResponse } : activity
      )
    )
  }

  const handleRemoveActivity = (id) => {
    const activityToRemove = activitiesList.find(activity => activity.id === id)
    setActivitiesList(prevActivitiesList => prevActivitiesList.filter(activity => activity.id !== id))
    setTotalMins(prevTotalMins => prevTotalMins - activityToRemove.minutes)
  }

  useEffect(() => {
    const total = activitiesList.reduce((sum, activity) => sum + activity.minutes, 0)
    setTotalMins(total)
  }, [activitiesList])

  useEffect(() => {
    const hours = (Math.floor(totalMins / 60)).toString().padStart(2, '0')
    const mins = (totalMins % 60).toString().padStart(2, '0')
    setTotalHoursMins(`${hours}:${mins}`)
  }, [totalMins])
  
  return (
    <section className="p-2 activities">
      <h4 className="pt-0">Activities Calculator</h4>
      <table className="table">
        <thead>
          <tr>
            <td>Activity</td>
            <td>Mins/week</td>
            <td>Delete</td>
          </tr>
        </thead>
        <tbody>
          {activitiesList.map(activity => (
            <tr className="hover" key={activity.id}>
              <td>
                <input
                  aria-label="Activity"
                  className="input w-full min-w-48"
                  type="text" 
                  value={activity.text} 
                  placeholder="Activity"
                  onChange={event => handleValueChange(activity.id, event.target.value)} 
                />
              </td>
              <td>
                <input 
                  aria-label="Minutes"
                  className="input max-w-24"
                  type="number" 
                  value={activity.minutes} 
                  placeholder="0"
                  min={0}
                  onChange={event => handleValueChange(activity.id, event.target.value, 'minutes')} 
                />
              </td>
              <td><button className="btn" onClick={() => handleRemoveActivity(activity.id)} aria-label="Delete activity"><CrossCircledIcon className="large-icon" aria-hidden="true" /></button></td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="btn mt-4" onClick={handleAddActivity}>Add activity <PlusCircledIcon className="large-icon" aria-hidden="true" /></button>
      <p className="text-lg mt-4">Your total time per week (HH:MM): <span className="bg-success text-neutral :inline-block py-1 px-2 ml-1 font-bold rounded-lg">{totalHoursMins}</span></p>
    </section>
  )
}

export default ActivitiesCalculator